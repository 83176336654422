import React from 'react'
import './App.css';
import Banner from "./components/Banner";
function App() {
  return (
    <div>
      <Banner />
    </div>
  );
}

export default App;
